.Informative {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 0 35px;
  background-color: var(--color-white);
  z-index: 4;

  display: flex;
  flex-flow: column;
}

.Informative__close {
  position: absolute;
  top: 28px;
  right: 40px;

  width: 34px;
  height: 34px;

  text-transform: uppercase;

  color: var(--color-inverse);
  /* font-size: var(--font-size-small); */

  cursor: pointer;

  transition: transform 1s;
}

.Informative__close-path {
  stroke-dasharray: 270;
  stroke-dashoffset: 0;

  transition: stroke-dashoffset 0.4s ease-in-out;
}

.Informative__close:hover {
  transform: scale3d(1.08, 1.08, 1.08) perspective(1px);
}

.Informative__close:hover .Informative__close-path {
  stroke-dashoffset: 270;
}

.Informative__container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 120px 0 60px 0;
}

.Informative__container ul,
.Informative__container ol {
  padding: 0 17px;
}

.Informative__container ol,
.Informative__container ul,
.Informative__container p {
  font-size: var(--font-size-medium);
}

.Informative blockquote {
  margin: 12px;
}

.Informative a {
  color: var(--color-inverse);
}

.Informative table {
  max-width: 99% !important;
  border-radius: 0 !important;
  overflow: visible !important;
}

.Informative #ot-sdk-cookie-policy .ot-sdk-container {
  margin-top: 40px;
  width: 100% !important;
  font-size: var(--font-size-medium) !important;
}

.Informative #ot-sdk-cookie-policy .ot-sdk-container * {
  color: var(--color-inverse) !important;
  font-size: 100% !important;
}

.Informative .ot-sdk-show-settings {
  margin-top: 40px;

  color: var(--color-inverse) !important;
  border-color: var(--color-inverse) !important;
}
