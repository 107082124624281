.Win {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;

  width: 100%;
  height: 100%;

  overflow: auto;
  background: transparent;

  padding: 0 40px;
}

.Win__model {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}


.Win__content {
  margin-top: 150%;
  margin-bottom: 40px;
}

.Win__title,
.Win__description {
  color: var(--color-white);
  text-transform: uppercase;

  text-align: center;
}

.Win__title {
  font-size: var(--font-size-large);
  font-weight: normal;
}
