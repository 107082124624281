.DesktopLayer {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #ECEDEC;
  z-index: 10;

  --color-inverse: var(--color-black);
}

.DesktopLayer__grid {
  display: flex;

  width: 100%;
  height: 100%;
}

.DesktopLayer__image {
  width: 100%;
  height: 100%;

  flex: 0 0 auto;

  background-image: url('./flower.jpg');
  background-repeat: no-repeat;
  background-size: contain;
}

.DesktopLayer__bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 0;

  padding-bottom: 6%;
}

.DesktopLayer__bar > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 0 40px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--color-white);
}

.DesktopLayer__bar #site-logo {
  margin: 0;
  width: 190px;
}

.DesktopLayer__text {
  position: absolute;
  top: 0;
  right: 0;

  width: 45%;
  height: 100%;

  flex: 0 0 auto;

  display: flex;

  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding: 60px;

  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.DesktopLayer__logo {
  width: 100%;
  max-width: 70%;
  margin-bottom: 6px;
}

.DesktopLayer__description {
  line-height: 1.5;
  max-width: 620px;
  font-size: var(--font-size-large);
}

.DesktopLayer__description strong {
  /* color: #b9202d; */
  font-weight: normal;
}

.DesktopLayer__title {
  font-size: var(--font-size-huge);
}

.DesktopLayer__qr {
  width: 150px;
}

@media screen and (max-width: 1050px) {
  .DesktopLayer__bar {
    padding-bottom: 12%;
  }

  .DesktopLayer__bar #site-logo {
    width: 130px;
  }

  .DesktopLayer__image {
    display: none;
  }

  .DesktopLayer__text {
    padding: 15px;
    width: 100%;
  }

  .DesktopLayer__description,
  .DesktopLayer__title {
    max-width: 360px;
    font-size: var(--font-size-small);
  }
}
