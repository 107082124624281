.Lang-Switcher {
  display: inline-flex;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  margin: auto;

  pointer-events: all;
}

.Lang-Switcher__btn {
  text-transform: uppercase;
  padding: 5px;

  font-size: var(--font-size-small);
  color: var(--color-inverse);

  cursor: pointer;
  transition: color 1s;
}

@media screen and (min-width: 421px) {
  .Lang-Switcher {
    position: fixed;
    top: 25px;
    left: 40px;
    bottom: auto;

    z-index: 10;
  }

  .Lang-Switcher__btn {
    font-size: var(--font-size-huge);
  }
}
