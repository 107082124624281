.Counter {
  position: relative;
  width: 100px;
  height: 100px;

  transform-origin: 50% 50%;
}

.Counter__circle {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.Counter__mask {
  overflow: hidden;
  line-height: 1;
}

.Counter__mask > * {
  line-height: 1;
}

.Counter__value {
  position: absolute;
  top: -5px;
  left: 3px;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  font-size: 50px;
  font-family: var(--font-family-serif);
  font-weight: normal;

  color: var(--color-white);
}
