.Header {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;

  margin: 0 30px;

  pointer-events: none;

  z-index: 6;
}

.Header::before {
  content: '';
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  bottom: -20px;

  z-index: -1;

  background: transparent;
  opacity: 0;

  transition: opacity 1s;
}

body.t-light .Header::before {
  background: var(--color-white);
}

body.is-scrolled .Header::before {
  opacity: 1;
}
