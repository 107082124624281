
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: all 5000s ease-in-out 0s;
  transition-property: color, background-color;
}

::-webkit-input-placeholder {
  color: var(--color-white);
}

::-moz-placeholder {
  color: var(--color-white);
}

:-ms-input-placeholder {
  color: var(--color-white);
}

:-moz-placeholder {
  color: var(--color-white);
}

.Input {
  display: block;
  position: relative;
}

.Input input {
  display: block;
  width: 100%;
  border-bottom: solid 1px var(--color-white);
  padding: 5px;

  font-size: var(--font-size-medium);
  line-height: 1;

  background: transparent;
  color: var(--color-white);
  appearance: none;

  opacity: 1;
  border-radius: 0;
  appearance: none;

  outline: none;
}

.Input input.has-error {
  border-color: red;
}

.Checkbox {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 20px;
  color: var(--color-white);
  outline: none;

  cursor: pointer;
}

.Checkbox__label {
  padding-left: 10px;

  font-size: var(--font-size-medium);
  line-height: 1.2;
}

.Checkbox__label a {
  color: var(--color-white);
  padding-left: 4px;
}

.Checkbox__check {
  position: relative;
  top: 2px;
  border: solid 1px var(--color-white);
  background: transparent;
  flex: none;

  width: 20px;
  height: 20px;
}

.Checkbox > input:checked + .Checkbox__check {
  background: var(--color-white);
}

.Checkbox.has-error .Checkbox__check {
  border-color: red;
}

/* .Checkbox__error {
  @apply block
    absolute
    left-0
    mt-1;

  top: 100%;
} */

.Form {
  margin-top: 20px;
}

.Form legend {
  font-size: var(--font-size-small);
  color: var(--color-white);
  margin-bottom: 20px;
}

.Form__row {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.Form__divider {
  margin-bottom: 40px;
}

.Form__submit {
  text-align: center;
}

.Form__nb {
  color: var(--color-white);

  font-size: var(--font-size-small);
  line-height: 1.6;

  margin-bottom: 40px;
}
