@font-face {
  font-family: 'Bodoni 72';
  src: url('./fonts/Bodoni72/Bodoni72-Bold.woff2') format('woff2'),
      url('./fonts/Bodoni72/Bodoni72-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni 72';
  src: url('./fonts/Bodoni72/Bodoni72-BookIta.woff2') format('woff2'),
      url('./fonts/Bodoni72/Bodoni72-BookIta.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni 72';
  src: url('./fonts/Bodoni72/Bodoni72-Book.woff2') format('woff2'),
      url('./fonts/Bodoni72/Bodoni72-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gill Sans Nova';
  src: url('./fonts/GillSansNova/GillSansNova-Light.woff2') format('woff2'),
      url('./fonts/GillSansNova/GillSansNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-family-sans: 'Gill Sans Nova', sans-serif;
  --font-family-serif: 'Bodoni 72', serif;

  --color-white: #fff;
  --color-black: #000;
  --color-red: #55130f;

  --color-inverse: var(--color-white);

  --font-size-tiny: 9px;
  --font-size-small: 11px;
  --font-size-medium: 14px;
  --font-size-large: 18px;
  --font-size-huge: 23px;
}

::-webkit-scrollbar {
  position: relative;
  z-index: 10;

  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
}


*, :after, :before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

*, ::after, ::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

html {
  overscroll-behavior: none;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* html,
body {
} */

body {
  margin: 0;
  /* overflow: hidden; */
  background: black;
}

body.t-light {
  --color-inverse: var(--color-black);
}

body,
button,
input {
  font-family: var(--font-family-sans);
}

img {
  max-width: 100%;
  vertical-align: middle;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
