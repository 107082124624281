.App {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  margin: auto;
  background: black;

  width: 100%;
  height: 100%;
}

@media screen and (min-width: 421px) {
  .App {
    width: 420px;
    height: 736px;
  }
}

.App__transitioner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
