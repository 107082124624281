.Score {
  position: absolute;
  top: -10px;
  bottom: 0;
  right: -5px;
  width: 60px;
  height: 60px;
  /* height: 60px; */

  transform-origin: 50% 50%;
}

.Score__circle {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: block;

  transform-origin: 50% 50%;
}

.Score__mask {
  overflow: hidden;
  line-height: 1;
}

.Score__mask > * {
  line-height: 1;
}

.Score__value {
  position: absolute;
  top: -2px;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  font-size: 20px;
  font-family: var(--font-family-serif);
  font-weight: normal;

  color: var(--color-white);
}
