/* .bounce-in-top {
  animation: bounce-in-top 2s ease infinite;
}

@keyframes bounce-in-top {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  60% {
    opacity: 1;
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
} */

.bounce2 {
  animation: bounce2 3s ease-out infinite;
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
	40% { transform: translateY(-6px); }
	60% { transform: translateY(-4px); }
}

.Home {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;

  width: 100%;
  height: 100%;
  overflow: auto;
  background: transparent;
}

.Home__content {
  width: 100%;
  height: calc(100%);
  padding: 100px 0 40px 0;

  display: flex;
  flex-flow: column;

  align-items: center;
  justify-content: space-between;
}

.Home__el {
  text-align: center;
  position: relative;
  z-index: 1;
}

.Home__model {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

/* .Home__model canvas {
  touch-action: none;
} */

.Home__title {
  margin: 0;

  font-size: var(--font-size-large);
  color: var(--color-white);
  text-transform: uppercase;
}

.home__subtitle {
  margin: 20px 0 0 0;
}

.Home__scroll {
  background: var(--color-red);
}

.Home__indicator {
  position: relative;
  top: 10px;
  margin-top: 15px;
  width: 20px;
}

.Home__excerpt {
  padding: 20px 15px;
  color: var(--color-white);
  text-align: center;
}

.Home__excerpt h2 {
  font-size: var(--font-size-large);
  font-weight: normal;
  text-transform: uppercase;
}

.Home__excerpt p {
  font-size: var(--font-size-medium);
}

.Home__footer {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

.Home__footer > a,
.Home__footer > button {
  font-size: var(--font-size-medium);
  text-decoration: underline;
  color: var(--color-white);
}
