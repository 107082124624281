.Instructions {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 40px 60px;
  z-index: 4;

  display: flex;
  flex-flow: column;
}

.Instructions__close {
  position: absolute;
  top: 28px;
  right: 40px;

  width: 34px;
  height: 34px;

  text-transform: uppercase;

  color: var(--color-white);
  /* font-size: var(--font-size-small); */

  cursor: pointer;

  transition: transform 1s;
}

.Instructions__close-path {
  stroke-dasharray: 270;
  stroke-dashoffset: 0;

  transition: stroke-dashoffset 0.4s ease-in-out;
}

.Instructions__close:hover {
  transform: scale3d(1.08, 1.08, 1.08) perspective(1px);
}

.Instructions__close:hover .Instructions__close-path {
  stroke-dashoffset: 270;
}

.Instructions__container {
  max-width: 80px;
  padding-top: 40px;
  margin: auto;

  flex: 1;
  display: flex;
  flex-flow: column;

  justify-content: center;
  user-select: none;
  pointer-events: none;

  overflow: hidden;
}

.Instructions__lipstick {
  position: relative;
  width: 80px;
  height: 320px;
}

/* .Instructions__refill,
.Instructions__base,
.Instructions__cap, {
  max-width: 100%;
  pointer-events: none;
  user-select: none;
} */

.Instructions__refill {
  max-width: 100%;
  padding: 0 5px;
}

.Instructions__base {
  position: relative;
  max-width: 100%;
  z-index: 2;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.Instructions__cap {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;

  z-index: 2;
}

.Instructions__label {
  position: relative;

  display: flex;
  align-items: center;
  flex-flow: nowrap;

  text-transform: uppercase;
  text-align: center;
  color: var(--color-white);
  font-size: var(--font-size-medium);

  /* background: black; */
  height: 70px;
}

.Instructions__label > div {
  width: 100%;
  flex: 1;
}

.Instructions__nav {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Instructions__nav li {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  overflow: hidden;

  border: solid 1px var(--color-white);
  background: transparent;
  margin: 4px;
}

.Instructions__nav li::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-white);

  opacity: 0;
  transition: opacity 450ms;
}

.Instructions__nav li.is-active::after {
  opacity: 1;
}
