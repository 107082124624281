.Play {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;

  width: 100%;
  height: 100%;
  overflow: hidden;

  background: transparent;
}

.Play__points {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;

  user-select: none;
  pointer-events: none;

  transform: translate(-50%, -50%);
}

.Play__counter {
  pointer-events: none;
}
