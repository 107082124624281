.Button {
  width: 220px;
  height: 50px;
  border-radius: 23px;
  margin: 0 auto;

  overflow: hidden;

  font-family: var(--font-family-serif);
  font-weight: bold;
}

.Button a,
.Button button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border-radius: 23px;

  overflow: hidden;

  font-size: 22px;
  letter-spacing: 1.5px;
  line-height: 1;
  /* background: transparent; */
  text-decoration: none;
  border: none;

  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; */

  cursor: pointer;
}

/* .Button-primary {
  border: solid 1px white;
  background: transparent;
  color: white;
} */

.Button-primary a,
.Button-primary button {
  border: solid 1px var(--color-inverse);
  background: transparent;
  color: var(--color-inverse);
}

.Button-small a,
.Button-small button {
  font-size: 16px;
}


.Button-secondary a,
.Button-secondary button {
  background: #fff;
  color: var(--color-red);
  mix-blend-mode: lighten;
}
