.Submission {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 4;

  width: 100%;
  height: 100%;

  overflow: auto;
  background: transparent;

  padding: 0 40px;
}

.Submission__content {
  /* margin-top: 150%; */
  width: 100%;
  height: 100%;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  text-align: center;
}

/* .Submission__title, */
.Submission__description {
  color: var(--color-white);
  font-size: 24px;
  text-transform: uppercase;
}

.Submission__cta {
  flex: 0 0 auto;
  margin-top: 80px;
}

/* .Submission__title {
  font-size: var(--font-size-large);
  font-weight: normal;
} */
